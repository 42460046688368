import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.scss";
import nispandLogo from "../../../../Assets/NispandLogo.png";
// import { nispandLogo, signupNispandLogo } from "../../../../Assets/icons";
import { Button, Steps, } from "antd";
import { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Badge, } from "antd/es";
// import { plus } from "../../Assets/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";

import { signupUploadFile } from "../../../Dashboard/Constants/signup.upload.helper";
// import PlanSelectViaSignUp from "../../Components/SignUpComponent/PlanSelectViaSignUp";
import PlanSelectViaSignUp from '../../../../Components/SignUpComponent/PlanSelectViaSignUp'
import { Country, State, City } from "country-state-city";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import {
  signUpCompanyDeatilsApi,
  signUpFirstNameLastNameEmailApi,
  signUpNumberUsers,
  signUpSetPassword,
  signUpVerifyOtp,
} from "./api";
import FileUploader from "../../../../Components/FileUploader";
// import SignUpRazorPay from "../SignUpRazorPay";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SignUp = () => {
  const [current, setCurrent] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [seconds, setSeconds] = useState(30);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessLogo, setBusinessLogo] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [userRole, setUserRole] = useState("");
  const [monthlyCount, setMonthlyCount] = useState("0");
  const [annualCount, setAnnualCount] = useState("0");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("Haryana");
  const [selectedCity, setSelectedCity] = useState("Gurgaon");
  const [isBookDemoChecked, setIsBookDemoChecked] = useState(false);
  const [imgAsset, setImgAsset] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const [paymentModal, setPaymentModal] = useState({
    paymentSuccessful: false,
    paymentUnsuccessful: false,
    paymentError: "",
  });
  const [successPaymentResponse, setSuccessPaymentResponse] = useState({
    razorpayPaymentId: "",
    razorpayOrderId: "",
    razorpaySignature: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const passwordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };


  const onMonthlyCountChange = (value) => {
    if (value === "" || isNaN(value)) {
      setMonthlyCount("0");
    } else {
      setMonthlyCount(value);
    }
  };

  const onAnnualCountChange = (value) => {
    if (value === "" || isNaN(value)) {
      setAnnualCount("0");
    } else {
      setAnnualCount(value);
    }
  };

  const incrementMonthly = () => {
    setMonthlyCount((prevCount) => String(parseInt(prevCount) + 1));
  };

  const decrementMonthly = () => {
    if (parseInt(monthlyCount) > 0) {
      setMonthlyCount((prevCount) => String(parseInt(prevCount) - 1));
    }
  };

  const incrementAnnual = () => {
    setAnnualCount((prevCount) => String(parseInt(prevCount) + 1));
  };

  console.log(annualCount);
  console.log(monthlyCount);

  const decrementAnnual = () => {
    if (parseInt(annualCount) > 0) {
      setAnnualCount((prevCount) => String(parseInt(prevCount) - 1));
    }
  };

  const startTimer = () => {
    const newIntervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(newIntervalId);
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);
    setIntervalId(newIntervalId);
  };


  const handleResend = async () => {
    try {
      const { data } = await signUpFirstNameLastNameEmailApi({
        firstName: firstName,
        lastName: lastName,
        email: email,
      });
      if (data.success) {
        // message.success(`OTP resent to your ${email}`);
        toast.success(`OTP resent to your ${email}`);
        setSeconds(30);
        clearInterval(intervalId);
        startTimer();
      }
    } catch (error) {
      // message.error("Failed to resend OTP. Please try again.");
      toast.error("Failed to resend OTP. Please try again.")
    }
  };

  // useEffect(() => {
  //   let intervalId;
  //   if (current === 1) {
  //     intervalId = setInterval(() => {
  //       setSeconds((prevSeconds) => {
  //         if (prevSeconds === 1) {
  //           clearInterval(intervalId);
  //         }
  //         return prevSeconds - 1;
  //       });
  //     }, 1000);
  //   }
  //   return () => clearInterval(intervalId);
  // }, [current]);


  useEffect(() => {
    startTimer();
    return () => clearInterval(intervalId);
  }, []);

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleSubmit = async () => {
    // setCurrent(4)
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === ""
    ) {
      // message.error("First Name, Last Name and Email are required.");
      toast.error("First Name, Last Name and Email are required.");
      return;
    }
    if (emailError) {
      // message.error("Invalid email address.");
      toast.error("Invalid email address.");
      return;
    }
    try {
      const { data } = await signUpFirstNameLastNameEmailApi({
        firstName: firstName,
        lastName: lastName,
        email: email,
      });
      if (data.success) {
        // message.success(`OTP sent to your ${email}`);
        toast.success(`OTP sent to your ${email}`);
        setCurrent(current + 1);
      }
    } catch (error) {
      if (error.response.status === 400) {
        // message.error("This email already exists. You can try logging in.");
        toast.error("This email already exists. You can try logging in.");
      } else {
        // message.error("An error occurred while processing your request");
        toast.error("An error occurred while processing your request");
      }
    }
  };

  const next = () => {
    if (current === 0) {
      handleSubmit();
    } else if (current === 1) {
      handleSubmitOtp();
    } else if (current === 2) {
      handleSubmitPassword();
    } else if (current === 3) {
      handleSubmitCompanyDetails();
    } else {
      // message.error("This page is not available.");
      toast.error("This page is not available.");
    }
  };

  const handleBackspace = (index) => {
    const newOtp = [...otp];
    newOtp[index] = "";
    setOtp(newOtp);
    if (index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleOtpChange = (index, value) => {
    if (!isNaN(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < otp.length - 1 && value !== "") {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value === "Backspace") {
      handleBackspace(index);
    }
  };

  const handleSubmitOtp = async () => {

    if (otp.includes("")) {
      // message.error("Invalid OTP");
      toast.error("Invalid OTP");
    } else {
      const enteredOtp = otp.join("");
      console.log("Entered OTP:", enteredOtp);
      try {
        const verifypayload = {
          email: email,
          otp: enteredOtp,
          auth: true,
          type: "BUSINESS",
        };
        const { data } = await signUpVerifyOtp(verifypayload);
        if (data.success) {
          // message.success(`Congratulations you verified your ${email}`);
          toast.success(`Congratulations! Verification successful.`);
          sessionStorage.setItem("signUpToken", data.token);

          setCurrent(current + 1);
        }
      } catch (error) {
        // message.error("OTP not matched");
        toast.error("OTP not matched");
      }
    }
  };

  const handleSubmitPassword = async () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      // return message.error(
      //   "Password must contain at least one lowercase letter, one uppercase letter, one digit and be at least 8 characters long."
      // );
      return toast.error(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit and be at least 8 characters long."
      );
    } else if (password !== confirmPassword) {
      // return message.error("Password doen't match.");
      return toast.error("Password doen't match.");
    } else {
      try {
        const { data } = await signUpSetPassword({
          newPassword: confirmPassword,
          confirmPassword: confirmPassword,
          email: email,
        });
        console.log(data);
        if (data.success) {
          // message.success("Password set successfully.");
          toast.success("Password set successfully.");
          setCurrent(current + 1);
        }
      } catch (error) {
        // message.error("Error something.");
        toast.error("Error something.");
      }
    }
  };

  const handleNumberOfUsers = async () => {
    if (monthlyCount <= 0 && annualCount <= 0) {
      toast.error("Enter Valid Number!")
      // return message.error("Enter valid number.");
    } else {
      const body = {
        plans: {
          monthly: {
            id: "6283886e35600c0013b8ac69",
            quantity: monthlyCount,
          },
          annual: {
            id: "62838c9d35600c0013b8ad31",
            quantity: annualCount,
          },
        },
        offerId: "",
        objectType: "BUSINESS",
      };

      try {
        const { data } = await signUpNumberUsers(body); // Assuming signUpNumberUsers returns data with an 'id' field
        const options = {
          key: "rzp_live_QtbEPsWyApws1Y", // Enter the Key ID generated from the Dashboard
          currency: data?.currency,
          amount: data?.amount,
          name: "Nispand",
          description: "Plan Purchase",
          image: "https://nispand-prods.s3.ap-south-1.amazonaws.com/Images/brandlogo.png",
          order_id: data?.id, // Use the 'id' obtained from signUpNumberUsers as the order_id
          handler: function (response) {
            setSuccessPaymentResponse({
              ...successPaymentResponse,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            });
            setPaymentModal({ ...paymentModal, paymentSuccessful: true });

            // alert(response.razorpay_payment_id)
            // alert(response.razorpay_order_id)
            // alert(response.razorpay_signature)
            toast.success("Thankyou for subscribing to Nispand for Business!")
            setTimeout(() => {
              navigate("/auth/login");
            }, 2000);
          },
          // prefill: {
          //   name: name,
          //   email: email,
          // },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399CC",
          },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function async(response) {
          setPaymentModal({
            ...paymentModal,
            paymentUnsuccessful: true,
            paymentError: response.error.description,
          });
        });
        rzp1.open();
      } catch (error) {
        console.error("Error:", error);
        // message.error("Something went wrong.");
      }
      console.log(successPaymentResponse, 'biilling')
    }

  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handleSubmitCompanyDetails = async () => {
    const organisationDetailsData = {
      companyName,
      phoneNumber,
      companySize,
      country: selectedCountry.name,
      state: selectedState.name,
      city: selectedCity.name,
      designation: userRole,
      businessLogo: '',
      bookingRequest: isBookDemoChecked,
    };
    console.log("Organisation Details Data:", organisationDetailsData);
    if (
      companyName === "" ||
      phoneNumber === "" ||
      companySize === "" ||
      selectedCountry.name === undefined ||
      selectedState.name === undefined ||
      selectedCity.name === undefined ||
      userRole === "" ||
      businessLogo === undefined
      // businessLogo == ""
    ) {
      // return message.error("Please fill in all the details.");
      return toast.error("Please fill in all the details.");
    }
    try {

      const imgAssetURL = await signupUploadFile(
        imgAsset,
        "CATEGORY-IMAGE",
        setThumbnailUploadPercentage,
        30,

      );
      const { data } = await signUpCompanyDeatilsApi({
        companyName,
        phoneNumber,
        companySize,
        country: selectedCountry.name,
        state: selectedState.name,
        city: selectedCity.name,
        designation: userRole,
        businessLogo: imgAssetURL,
        bookingRequest: isBookDemoChecked,
      });

      if (data.success) {
        // message.success("Details Updated.");
        toast.success("Details Updated.");
        setCurrent(current + 1);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleCheck = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setIsBookDemoChecked(e.target.checked);
  };

  const passwordConditions = {
    symbols: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /\d/.test(password),
  };

  const steps = [
    {
      title: (
        <div>
          <h4 className="white-text-steps">Your Details</h4>
        </div>
      ),
      description: (
        <div>
          <p className={`white-text-steps ${current === 0 ? 'active-text-steps' : ''}`}>
            Please provide your name and email
          </p>
        </div>
      ),
      content: (
        <div className="text-start">
          <h4 className="textStyle">Sign Up</h4>
          <p className="signUpParagraph">
            Some contextual text which mentions about being partner with Nispand
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-floating my-3">
              <input
                className="form-control"
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={handleFirstNameChange}
                autoComplete="off"
              />
              <label htmlFor="firstName" className="form-label">
                First Name *
              </label>
            </div>
            <div className="form-floating my-3">
              <input
                className="form-control"
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={handleLastNameChange}
                autoComplete="off"
              />
              <label htmlFor="lastName" className="form-label">
                Last Name *
              </label>
            </div>
            <div className="form-floating my-3">
              <input
                className="form-control"
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                onBlur={validateEmail}
                autoComplete="off"
              />
              <label htmlFor="email" className="form-label">
                Work Email *
              </label>
            </div>
          </form>
        </div>
      ),
    },
    {
      title: (
        <div>
          <h4 className="white-text-steps">Submit Otp</h4>
        </div>
      ),
      description: (
        <div>
          <p className={`white-text-steps ${current === 1 ? 'active-text-steps' : ''}`}>Otp verification</p>
        </div>
      ),
      content: (
        <div className="text-start">
          <h4 className="textStyle">Verify your email</h4>
          <p className="signUpParagraph">
            We’ve sent a wellness code on your email
          </p>
          <div
            className="d-flex justify-content-around my-4"
            style={{ flexDirection: "row" }}
          >
            {otp.map((digit, index) => (
              <input
                className="borderOtp"
                key={index}
                type="text"
                id={`otp-input-${index}`}
                maxLength="1"
                style={{
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                  marginRight: "5px",
                }}
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
              />
            ))}
          </div>
          {current === 1 && (
            <div className="text-center">
              {seconds > 0 ? (
                `Resend in ${seconds} sec`
              ) : (
                <button className="resendButton" onClick={handleResend}>
                  Resend
                </button>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      title: (
        <div>
          <h4 className="white-text-steps">Choose Password</h4>
        </div>
      ),
      description: (
        <div>
          <p className={`white-text-steps ${current === 2 ? 'active-text-steps' : ''}`}>Choose a secure password</p>
        </div>
      ),
      content: (
        <div className="text-start">
          <h4 className="textStyle">Set Password</h4>
          <div className="form-floating my-3">
            <input
              className="form-control"
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="visible-password-icon"
              onClick={passwordVisibility}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="form-floating my-3">
            <input
              className="form-control"
              type={showPassword1 ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="new-password"
            />
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password
            </label>
            <FontAwesomeIcon
              icon={showPassword1 ? faEyeSlash : faEye}
              className="visible-password-icon"
              onClick={passwordVisibility1}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="d-flex my-4 text-center justify-content-around">
            <Badge
              className={password.length >= 8 ? "check-green" : "check-red"}
              count={
                <FontAwesomeIcon icon={password.length >= 8 ? faCheck : ""} />
              }
            >
              <h3>8+</h3>
              <p>Characters</p>
            </Badge>
            <Badge
              className={
                passwordConditions.uppercase ? "check-green" : "check-red"
              }
              count={
                <FontAwesomeIcon
                  icon={passwordConditions.uppercase ? faCheck : ""}
                />
              }
            >
              <h3>AA</h3>
              <p>Uppercase</p>
            </Badge>
            <Badge
              className={
                passwordConditions.lowercase ? "check-green" : "check-red"
              }
              count={
                <FontAwesomeIcon
                  icon={passwordConditions.lowercase ? faCheck : ""}
                />
              }
            >
              <h3>aa</h3>
              <p>Lowercase</p>
            </Badge>
            <Badge
              className={
                passwordConditions.number ? "check-green" : "check-red"
              }
              count={
                <FontAwesomeIcon
                  icon={passwordConditions.number ? faCheck : ""}
                />
              }
            >
              <h3>80</h3>
              <p>Number</p>
            </Badge>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div>
          <h4 className="white-text-steps">Organisation Details</h4>
        </div>
      ),
      description: (
        <div>
          <p className={`white-text-steps ${current === 3 ? 'active-text-steps' : ''}`}>Tell us about your organisation</p>
        </div>
      ),
      content: (
        <div className="text-start">
          <h4 className="textStyle">Company Details</h4>
          <p className="signUpParagraph">
            Some basic information about your organization, so we know you
            better
          </p>
          <form>
            <div className="form-floating my-3">
              <input
                className="form-control"
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                autoComplete="off"
              />
              <label htmlFor="companyName" className="form-label">
                Company Name *
              </label>
            </div>
            <div className="form-floating my-1">
              <FileUploader
                file={imgAsset}
                setFile={setImgAsset}
                // dragText="Drop Cover Image here"
                heading="Select Image"
                accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
              />
            </div>
            <div className="form-floating my-3">
              <select
                className="form-select"
                id="companySize"
                value={companySize}
                onChange={(e) => setCompanySize(e.target.value)}
              >
                <option value="" disabled>
                  Select Company Size
                </option>
                <option value="0-25">0-25</option>
                <option value="26-300">26-300</option>
                <option value="301-5000">301-5000</option>
                <option value="5001-50000">5001-50000</option>
                <option value="50000+">50000+</option>
              </select>
              <label htmlFor="companySize" className="form-label">
                Company Size *
              </label>
            </div>
            <div className="form-floating my-3">
              <PhoneInput
                country={"in"}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </div>
            <div className="form-floating my-3">
              <Row>
                <Col lg={12}>
                  <label htmlFor="companyCountry" className="form-label">
                    Company Country *
                  </label>
                  <select
                    className="form-select"
                    id="companyCountry"
                    value={selectedCountry ? selectedCountry.name : "India"}
                    onChange={(e) => {
                      const selectedOption = Country.getAllCountries().find(
                        (option) => option.name === e.target.value
                      );
                      setSelectedCountry(selectedOption);
                    }}
                  >
                    <option value="">Select Country</option>
                    {Country.getAllCountries().map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col lg={6}>
                  <label htmlFor="companyState" className="form-label">
                    Company State *
                  </label>
                  <select
                    className="form-select"
                    id="companyState"
                    value={selectedState ? selectedState.name : "Haryana"}
                    onChange={(e) => {
                      const selectedOption = State?.getStatesOfCountry(
                        selectedCountry?.isoCode
                      )?.find((option) => option.name === e.target.value);
                      setSelectedState(selectedOption);
                    }}
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    {State?.getStatesOfCountry(selectedCountry?.isoCode)?.map(
                      (option) => (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select>
                </Col>
                <Col lg={6}>
                  <label htmlFor="companyCity" className="form-label">
                    Company City
                  </label>
                  <select
                    className="form-select"
                    id="companyCity"
                    value={selectedCity ? selectedCity.name : "Gurgaon"}
                    onChange={(e) => {
                      const selectedOption = City.getCitiesOfState(
                        selectedState?.countryCode,
                        selectedState?.isoCode
                      )?.find((option) => option.name === e.target.value);
                      setSelectedCity(selectedOption);
                    }}
                  >
                    <option value="">Select City</option>
                    {City.getCitiesOfState(
                      selectedState?.countryCode,
                      selectedState?.isoCode
                    )?.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </div>
            <div className="form-floating my-3">
              <select
                className="form-select"
                id="userRole"
                value={userRole}
                onChange={(e) => setUserRole(e.target.value)}
              >
                <option value="" disabled>
                  Select Your Role
                </option>
                <option value="Human Resources">Human Resources</option>
                <option value="Employee Health &amp; Wellness">
                  Employee Health &amp; Wellness
                </option>
                <option value="Employee Benifits">Employee Benifits</option>
                <option value="Company Executive / Senior Leader">
                  Company Executive / Senior Leader
                </option>
                <option value="Broker / Consultant">Broker / Consultant</option>
                <option value="Other">Other</option>
              </select>
              <label htmlFor="userRole" style={{ zIndex: '1' }} className="form-label">
                User Role *
              </label>
              <div className="form-check my-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={""}
                  id="flexCheckDefault"
                  onChange={handleCheck}
                />
                <label
                  className="form-check-label checkLabel"
                  htmlFor="flexCheckDefault"
                >
                  Book Demo
                  <p>I want Nispand to reach out to me for demo session</p>
                </label>
              </div>
            </div>
          </form>
        </div>
      ),
    },
    {
      title: (
        <div>
          <h4 className="white-text-steps">Select Subscription</h4>
        </div>
      ),
      description: (
        <div>
          <p className={`white-text-steps ${current === 4 ? 'active-text-steps' : ''}`}>Choose Subscription Plans</p>
        </div>
      ),
      content: (
        <div className="text-start">
          <h4 className="textStyle">Company Details</h4>
          <p className="signUpParagraph">
            Some basic information about your organisation, so we know you
            better
          </p>
          {/*}  <PlanSelectViaSignUp
        title="Monthly"
            description="Some short crisp one line about this goes here"
            NOL="No of Licenses"
            count={monthlyCount}
            onIncrement={incrementMonthly}
            onDecrement={decrementMonthly}
            onCountChange={onMonthlyCountChange}
          />*/}
          <PlanSelectViaSignUp
            title="Annual"
            description="1 year of unlimited wellness with Nispand Premium!"
            NOL="No of Licenses"
            count={annualCount}
            onIncrement={incrementAnnual}
            onDecrement={decrementAnnual}
            onCountChange={onAnnualCountChange}
          />
        </div>
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
  }));

  return (
    <>
      <ToastContainer />
      <section>
        <Container fluid>
          <Row>
            <Col md={4} style={{ background: "#091624", height: "100vh", position: 'sticky', top: '0' }}>
              <img
                src={nispandLogo}
                alt="Nispand Business"
                className="my-5 mx-5"
              />
              <Steps
                current={current}
                items={items}
                direction="vertical"
                className=" my-5 px-5"
              />
              <p className="flex justify-start py-4 px-12 mt-24" onClick={() => navigate('/auth/login')} style={{ color: 'white', cursor: 'pointer' }}>Go Back To Login </p>
            </Col>
            <Col md={8}>
              <div className="contentStyle">
                {steps[current].content}
                <div
                  style={{
                    marginTop: 24,
                    display: "flex",
                  }}
                >
                  {current < steps.length - 1 && (
                    <Button
                      className="stepsBtn mb-4"
                      type="primary"
                      onClick={() => {
                        next();
                        console.log(current)
                      }}
                      style={{ width: "360px" }}
                    >
                      Continue
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      className="stepsBtn"
                      type="primary"
                      onClick={handleNumberOfUsers}
                      style={{ width: "360px" }}
                    >
                      Pay
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </>
  );
};

export default SignUp;
