import "./App.css";
import React, { useEffect, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { MainRoutes } from "./Constants/routes";
// import Pages from './Views/Authentication/Components/pages';You cannot render a <Router> inside another <Router>. You should never have more than one in your app.
// import Login from "./Views/Login";
// import Login from "./Views/Authentication/Components/Login";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./Views/Authentication/Auth.actions";
// import { isAuthorized } from "./Utils/localStorage";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "./Views/Dashboard/Components/Sidebar";
import Dashboard from "./Views/Dashboard";
// import Login from "./Auth/Login";
import Login from "./Views/Authentication/Components/Login";
import Auth from "./Auth";
// import SignIn from "./Auth/SignIn";
// import SignUp from "./Auth/SignUp/Index";
import SignUp from "./Views/Authentication/Components/SignUp/Index";
import Layout from "./Layout";
import ProtectedRoute from "./Utils/ProtectedRoute";
import NotFoundPage from "./Views/Dashboard/404.js";
import ForgetPassword from "./Views/Authentication/Components/ForgetPasswrod/index.js";
import SetPassword from "./Views/Authentication/Components/SetPassword/index.js";

function App() {
  const dispatch = useDispatch();
  // const [isLoggedIn, setIsLoggedIn] = useState(true);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  useEffect(() => {
    dispatch(fetchUserData());
  }, []);
  return (
    <>
      <Router basename={"/"}>
        <Routes>
          <Route path="/auth" element={<Auth />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="setpassword" element={<ForgetPassword />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="dashboard/home" replace  />} />
            {MainRoutes.map(({ Component, path, children, indexRoute }) => (
              <Route
                key={path} // Key should be placed on the outermost element in the map
                // {...(indexRoute ? {index:true } : {path})}
                path={path}
                element={
                  <ProtectedRoute>
                    <Component />
                  </ProtectedRoute>
                }
              >
                {(children || []).map(({ Component: Child, path, indexRoute }) => (indexRoute ? <Route index element={<ProtectedRoute>
                  <Child />
                </ProtectedRoute>} key={path} /> : <Route element={<ProtectedRoute>
                  <Child />
                </ProtectedRoute>} path={path} key={path} />))}
              </Route>

            ))}
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Router>
      {/* <Router>
        {isAuthorized() ? <Dashboard /> : <Login />}
      </Router> */}
    </>
  );
}

export default App;
